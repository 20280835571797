module.exports = {
    forkModels: [{ value: '36 Rythm GRIP / FIT 4', label: 'Fox Racing Shox 36 Rythm GRIP / FIT 4' },
        { value: '36 FLOAT E-Bike+ / E-Optimized GRIP / FIT 4', label: 'Fox Racing Shox 36 FLOAT E-Bike+ / E-Optimized GRIP / FIT 4' },
        { value: '36 Factory / Performance Elite Grip2', label: 'Fox Racing Shox 36 Factory / Performance Elite Grip2' },
        { value: '36 FLOAT GRIP / FIT 4', label: 'Fox Racing Shox 36 FLOAT GRIP / FIT 4' },
        { value: '38 Factory / Performance Elite Grip2', label: 'Fox Racing Shox 38 Factory / Performance Elite Grip2' },
        { value: '38 E-Bike Factory Grip2', label: 'Fox Racing Shox 38 E-Bike Factory Grip2' },
        { value: '34 Rythm GRIP / FIT 4', label: 'Fox Racing Shox 34 Rythm GRIP / FIT 4' },
        { value: '34 FLOAT GRIP / FIT 4', label: 'Fox Racing Shox 34 FLOAT GRIP / FIT 4' },
        { value: '34 FLOAT Factory / Performance Elite SC/FIT4', label: 'Fox Racing Shox 34 FLOAT Factory / Performance Elite SC/FIT4' },
        { value: '40 FLOAT Performance GRIP', label: 'Fox Racing Shox 40 FLOAT Performance GRIP' },
        { value: '40 FLOAT Factory / Performance Elite GRIP 2', label: 'Fox Racing Shox 40 FLOAT Factory / Performance Elite GRIP 2' },
        { value: 'Gold 35', label: 'Rock Shox Gold 35'},
        { value: 'Domain', label: 'Rock Shox Domain' },
        { value: 'Lyrik Ultimate', label: 'Rock Shox Lyrik Ultimate' },
        { value: 'Lyrik Select, Select+', label: 'Rock Shox Lyrik Select, Select+' },
        { value: 'Lyrik RCT3, RC2, RC', label: 'Rock Shox Lyrik RCT3, RC2, RC' },
        { value: 'Lyrik', label: 'Rock Shox Lyrik' },
        { value: 'Boxxer Select, Select+', label: 'Rock Shox Boxxer Select, Select+' },
        { value: 'Boxxer Ultimate', label: 'Rock Shox Boxxer Ultimate' },
        { value: 'Boxxer WC', label: 'Rock Shox Boxxer WC' },
        { value: 'Boxxer', label: 'Rock Shox Boxxer' },
        { value: 'Pike Ultimate', label: 'Rock Shox Pike Ultimate' },
        { value: 'Pike Select, Select+', label: 'Rock Shox Pike Select, Select+' },
        { value: 'Pike RC', label: 'Rock Shox Pike RC' },
        { value: 'Pike', label: 'Rock Shox Pike' },
        { value: 'Recon', label: 'Rock Shox Recon' },
        { value: 'Revelation', label: 'Rock Shox Revelation' },
        { value: 'Rock Shox 30', label: 'Rock Shox 30' },
        { value: 'Rock Shox 32', label: 'Rock Shox 32' },
        { value: 'Rock Shox 35', label: 'Rock Shox 35' },
        { value: 'SID Ultimate', label: 'Rock Shox SID Ultimate' },
        { value: 'SID Select, Select+', label: 'Rock Shox SID Select, Select+' },
        { value: 'Yari', label: 'Rock Shox Yari' },
        { value: 'ZEB Ultimate', label: 'Rock Shox ZEB Ultimate' },
        { value: 'ZEB Select, Select+', label: 'Rock Shox ZEB Select, Select+' },
        { value: 'Judy', label: 'Rock Shox Judy' },
        { value: 'Bomber Z1', label: 'Marzocchi Bomber Z1' },
        { value: 'Bomber Z2', label: 'Marzocchi Bomber Z2' },
        { value: 'Bomber ZI', label: 'Marzocchi Bomber ZI' },
        { value: 'Helm', label: 'Cane Creek Helm' },
        { value: 'Durolux', label: 'SR Suntour Durolux' },
        { value: 'Dorado', label: 'Manitou Dorado' },
        { value: 'DH 38', label: 'Öhlins DH 38' },
        { value: 'Diamond', label: 'DVO Diamond' },
        { value: 'Mezzer', label: 'Manitou Mezzer' },
        { value: 'Onyx', label: 'DVO Onyx' },
        { value: 'RXF', label: 'Öhlins RXF' },
        { value: 'Selva', label: 'Formula Selva' },
        { value: 'Era', label: 'EXT Era' }],
    terrainCategory: ['bikepark', 'trail', 'bikepark, trail'],
    usefulness: [{ value: 'not useful', label: 'NOT USEFUL' },
        { value: 'a bit useful', label: 'A BIT USEFUL' },
        { value: 'useful', label: 'USEFUL' },
        { value: 'very useful', label: 'VERY USEFUL' },]
}