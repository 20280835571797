import logo from './orange-1536x568.png';
import logoAI from './sagly-ai.png';
import logoAIIcon from './splash_icon.png';
import forkIcon from './fork-icon.png';
import checkMark from './check-mark.png';
import bgImage from './ezgif-3-f2084fdb1c.webp';
import './App.css';
import styles from './App.css';
import {modelSettings, units} from './model-settings.js';
import React, {createRef, useRef} from "react";
import ReactDOM from "react-dom";
//import Child from './Child';  // Child Component
import Select from 'react-select'
import Modal from 'react-modal';
import {forkModels} from "./config";
import {usefulness} from "./config";
import { ReactComponent as Bikepark } from './bikepark.svg';
import { ReactComponent as Trail } from './trail.svg';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import emailjs from '@emailjs/browser';
import ModalExplain from './modal.js';
import {brightBlackStyle} from "./GlobalStyles/colors";


const BASE_URL = process.env.REACT_APP_BASE_URL;


const customStylesModal = {
    content: {
        top: '30vh',
        left: '50vw',
        right: 'auto',
        bottom: 'auto',
        borderRadius: 10,
        flexDirection: "column",
        display: "inline-flex",
        justifyContent: "center",
        backgroundColor: brightBlackStyle,
        color: 'white',
        transform: 'translate(-50%, -50%)',
    },
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted white',
        color: state.isSelected ? 'orange' : 'white',
        backgroundColor: '#2d2d2d',
        padding: 10,
    }),
    control: (provided) => ({
        ...provided,
        // none of react-select's styles are passed to <Control />
        color: '#ffffff',
        placeholderColor: '#ffffff',
        backgroundColor: '#2d2d2d',
        margin: 10,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const color = '#ffffff';
        return { ...provided, opacity, transition, color};
    }
}


class App extends React.Component {
    constructor(props) {
        super(props);
        this.ref = createRef();

        this.state = {
            step: 0,
            weight: 0,
            height: 0,
            terrain: '',
            loading: false,
            forkModel: forkModels[0].value.toString(),
            predictData: {}
        };

        this.handleClick = this.handleClick.bind(this);


    }

    handleClick() {
        this.setState({step: this.state.step + 1});
        console.log("step", this.state.step);
    }

    handleClickFeedback() {
        this.setState({step: this.state.step + 1});
        console.log("step", this.state.step);
    }

    handleClickSubmit() {
        console.log('GetPrediction called', BASE_URL);
        let forkModelForBackend = this.state.forkModel.toString().replace(/ /g,"_");
        forkModelForBackend = forkModelForBackend.toString().replace(/\//g,"=");
        forkModelForBackend = forkModelForBackend.toString().replace(/,/g,"*");
        let terrain = this.state.terrain.replace(/, /g,"_");
        if (terrain === '') {
            terrain = 'bikepark_trail'
            this.setState({terrain: '-'});
        }
        this.setState({loading: true});
        fetch(`${BASE_URL}/predict/${this.state.weight},${this.state.height},${terrain},${forkModelForBackend}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json())
            .then(async (predictData) => {
                try {
                    if (predictData && predictData.message === "unauthorized, could not decode the token") {
                        return {showUnauthorizedError: true};
                    }
                    let settingsToShow;
                    console.log('predictData1', predictData.data, modelSettings);
                    for (let i in predictData.data) {
                        for (let j = 0; j < modelSettings.length; j++) {
                            if (modelSettings[j].Models === this.state.forkModel) {
                                if (j === 0) {
                                    console.log('i', modelSettings[j][i], i, predictData.data[i], modelSettings[j].hasOwnProperty(i));
                                }
                                if (modelSettings[j][i] === 'FALSE' || !modelSettings[j].hasOwnProperty(i)) {
                                    console.log('delete', modelSettings[j][i], i, predictData.data[i]);
                                    delete predictData.data[i]
                                }
                            }
                        }
                    }
                    this.setState({predictData: predictData.data});
                    this.setState({step: this.state.step + 1});
                    this.setState({loading: false});
                    console.log('predictData2', predictData);
                } catch (e) {
                    console.log("error while getting setups", e)
                }
            })
            .catch(error => {
                console.log("error occurred");
                console.log(JSON.stringify(error));
            });
    }


    handleClickTerrain(props) {
        console.log('props', props);
        let oppositeTerrain;
        props === 'bikepark' ? oppositeTerrain = 'trail' : oppositeTerrain = 'bikepark'
        if (this.state.terrain !== props && this.state.terrain !== 'bikepark, trail' && this.state.terrain !== '') {
            this.setState({terrain: 'bikepark, trail'});
        } else if (this.state.terrain === props) {
            this.setState({terrain: ''});
        } else if (this.state.terrain.toString().indexOf(props) !== -1) {
            this.setState({terrain: oppositeTerrain});
        } else {
            this.setState({terrain: props});
        }
    }

    componentDidMount(){
        document.title = "SAGLY AI FORK SETUP"
    }

    handleClickBack() {
        this.setState({step: this.state.step - 1});
        console.log("step", this.state.step);
    }

    handleClickRestart() {
        this.setState({step: 0,
            weight: 0,
            height: 0,
            terrain: '',
            loading: false,
            mail: '',
            feedback: '',
            name: '',
            usefulness: '',
            showModal: false,
            forkModel: forkModels[0].value});
        console.log("step", this.state.step);
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({ weight: value });
    };

    showExplanationModal () {
        if (this.state.showModal) {
            this.setState({ showModal: false });
        } else {
            this.setState({ showModal: true });
        }
    };


    terrainButton = (props) => {
      if (props.terrainType === 'bikepark') {
          if (this.state.terrain.toString().indexOf('bikepark') !== -1) {
              return (<button onClick={this.handleClickTerrain.bind(this, props.terrainType)} className="terrain-active"><Bikepark/>BIKEPARK</button>)
          } else {
              return (<button onClick={this.handleClickTerrain.bind(this, props.terrainType)} className="terrain-deactivated"><Bikepark/>BIKEPARK</button>)
          }
      } else {
          if (this.state.terrain.toString().indexOf('trail') !== -1) {
              return (<button onClick={this.handleClickTerrain.bind(this, props.terrainType)} className="terrain-active"><Trail/>TRAIL</button>)
          } else {
              return (<button onClick={this.handleClickTerrain.bind(this, props.terrainType)} className="terrain-deactivated"><Trail/>TRAIL</button>)
          }
      }
    };

    handleChangeHeight = (event) => {
        const value = event.target.value;
        this.setState({ height: value });
    };

    handleChangeName = (event) => {
        const value = event.target.value;
        this.setState({ name: value });
    };

    handleChangeMail = (event) => {
        const value = event.target.value;
        this.setState({ mail: value });
    };

    handleChangeFeedback = (event) => {
        const value = event.target.value;
        this.setState({ feedback: value });
    };

    selectValueForkModel = (prop) => {
        console.log('prop', prop.value);
        this.setState({ forkModel: prop.value });
    };

    selectUsefulness = (prop) => {
        console.log('prop', prop.value);
        this.setState({ usefulness: prop.value });
    };

    spinner = () => {
        if (this.state.loading) {
            return (
                <div className="spinner-container">
                    <div className="loading-spinner">
                    </div>
                </div>
            );
        } else {
            return <div/>
        }
    }

    explainModal = () => {
        if (this.state.showModal) {
            return (<Modal
                isOpen={this.state.showModal}
                onRequestClose={!this.state.showModal}
                style={customStylesModal}
                contentLabel="Modal Explanation"
            >
                <h2 className="Pitch">How Does SAGLY FORK AI WORK?</h2>
                <div>SAGLY FORK AI takes more than 30.000 fork setups into account. The setups are created from passionate
                mountain bikers like you in the SAGLY mobile app. The algorithm considers your input and searches for
                linear correlations within the existing fork setups and predicts personalized settings for you.</div>
                <button className="Back-button-modal" onClick={this.showExplanationModal.bind(this)}>CLOSE</button>
            </Modal>)
        } else {
            return <div/>
        }
    }

    mainContainer = (prop) => {
        console.log('prop', prop, this.state.predictData);
        let settingContainers = [];
        let settingContainersSecondRow = [];
        let settingContainersThirdRow = [];
        let settingContainersFourthRow = [];
        let settingContainersFifthRow = [];
        let counter = 0;
        for (let i in this.state.predictData) {
            counter++;
            if (counter<3) {
            const settingName = i.replaceAll('_', ' ').toUpperCase().split('VALUE')[0]
            settingContainers.push(
                <div className="setting-suggestion">
                    <p className="Description-Prediction">
                        {settingName}
                    </p>
                    <p className="Value-prediction">
                        {this.state.predictData[i]}
                    </p>
                    <p className="Description-Prediction">
                        {units[i]}
                    </p>
                </div>
            )
            }
            if (counter>2&&counter<5) {
                const settingName = i.replaceAll('_', ' ').toUpperCase().split('VALUE')[0]
                settingContainersSecondRow.push(
                    <div className="setting-suggestion">
                        <p className="Description-Prediction">
                            {settingName}
                        </p>
                        <p className="Value-prediction">
                            {this.state.predictData[i]}
                        </p>
                        <p className="Description-Prediction">
                            {units[i]}
                        </p>
                    </div>
                )
            }
            if (counter>4&&counter<7) {
                const settingName = i.replaceAll('_', ' ').toUpperCase().split('VALUE')[0]
                settingContainersThirdRow.push(
                    <div className="setting-suggestion">
                        <p className="Description-Prediction">
                            {settingName}
                        </p>
                        <p className="Value-prediction">
                            {this.state.predictData[i]}
                        </p>
                        <p className="Description-Prediction">
                            {units[i]}
                        </p>
                    </div>
                )
            }
            if (counter>6&&counter<9) {
                const settingName = i.replaceAll('_', ' ').toUpperCase().split('VALUE')[0]
                settingContainersFourthRow.push(
                    <div className="setting-suggestion">
                        <p className="Description-Prediction">
                            {settingName}
                        </p>
                        <p className="Value-prediction">
                            {this.state.predictData[i]}
                        </p>
                        <p className="Description-Prediction">
                            {units[i]}
                        </p>
                    </div>
                )
            }
            if (counter>9&&counter<11) {
                const settingName = i.replaceAll('_', ' ').toUpperCase().split('VALUE')[0]
                settingContainersFifthRow.push(
                    <div className="setting-suggestion">
                        <p className="Description-Prediction">
                            {settingName}
                        </p>
                        <p className="Value-prediction">
                            {this.state.predictData[i]}
                        </p>
                        <p className="Description-Prediction">
                            {units[i]}
                        </p>
                    </div>
                )
            }
        }
        return (<div><div className="Main-container-prediction" id='Settings'>
            {settingContainers}
        </div>
            <div className="Main-container-prediction" id='Settings'>
            {settingContainersSecondRow}
            </div>
                <div className="Main-container-prediction" id='Settings'>
            {settingContainersThirdRow}
                </div>  <div className="Main-container-prediction" id='Settings'>
            {settingContainersFourthRow}
            </div> <div className="Main-container-prediction" id='Settings'>
            {settingContainersFifthRow}</div>
        </div>)

    };

    sendEmail = (e) => {
        e.preventDefault();


    };

    async printDocument(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();
        console.log('e target', e, e.target);
        const current = this.ref.current;
        const test = await emailjs.sendForm('service_7jot6ib', 'template_8qk1wzc', e.target, '0k4SBD3j_3NFtnRPq');
        console.log(test);
        this.setState({step: this.state.step-1})
        const input = document.getElementById('divToPrint');
        await html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                let link = document.createElement('a');
                link.download = 'sagly-ai-setup-export.png';
                link.href = imgData;
                link.click();
            });
    }




    render() {
        const {step, weight, height, terrain, forkModel} = this.state;
        if (step === 0) {
            return (
                <div className="App">
                    <header className="App-header">
                        <img src={logoAI} className="App-logo" alt="logo"/>
                        <p className="Pitch">
                            CUSTOM SETTINGS FOR YOUR MOUNTAIN BIKE FORK BASED ON YOUR ..
                        </p>
                        <p className="List">
                            ⚖️ BODY PHYSIQUE<br/>
                            🚵️ RIDING SKILLS<br/>
                            ⛰️ RIDING TERRAIN<br/>
                            <img src={forkIcon} className="Check-mark-first" alt="logo"/>FORK MODEL
                        </p>
                        <p className="hint-first">
                            Our algorithm takes more than 30.000 fork mountain bike setups into account.
                        </p>
                    </header>
                    <button onClick={this.handleClick} className="Start-button">LET'S START</button>
                </div>
            );
        } else if (step === 1) {
            return (
                <div className="App">
                <header className="App-header">
                    <div className="header-container">
                        <img src={logoAIIcon} className="App-logo-very-small" alt="logo"/>
                    </div>
                    <p className="Pitch">
                        FIRST WE NEED SOME INFO ABOUT YOU!
                    </p>
                    <div className="Main-container">
                        <p className="Description">
                            What is your weight in KG?
                        </p>
                        <input
                            type="text"
                            className="Input"
                            onChange={this.handleChange}
                            placeholder="How much do you weight in KGs"
                            value={weight}
                        />
                    </div>
                </header>
                    <div className="Button-container">
                        <button onClick={this.handleClickBack.bind(this)} className="Back-button">GO BACK</button>
                        <button onClick={this.handleClick.bind(this)} className="Start-button">NEXT</button>
                    </div>
                </div>
            )
        } else if (step === 2) {
                return (
                    <div className="App">
                        <header className="App-header">
                            <div className="header-container">
                                <img src={logoAIIcon} className="App-logo-very-small" alt="logo"/>
                            </div>
                            <p className="List-selected">
                                <img src={checkMark} className="Check-mark" alt="logo"/>WEIGHT: {this.state.weight.toString()} KG<br/>
                            </p>
                            <div className="Main-container">
                                <p className="Description">
                                    What is your height in CM?
                                </p>
                                <input
                                    type="text"
                                    className="Input"
                                    onChange={this.handleChangeHeight}
                                    placeholder="How much do you weight in KGs"
                                    value={height}
                                />
                            </div>
                        </header>
                        <div className="Button-container">
                            <button onClick={this.handleClickBack.bind(this)} className="Back-button">GO BACK</button>
                            <button onClick={this.handleClick.bind(this)} className="Start-button">NEXT</button>
                        </div>
                    </div>
                )
        } else if (step === 3) {
            return (
                <div className="App">
                    <header className="App-header">
                        <div className="header-container">
                            <img src={logoAIIcon} className="App-logo-very-small" alt="logo"/>
                        </div>
                        <p className="List-selected">
                            <img src={checkMark} className="Check-mark" alt="logo"/>WEIGHT: {this.state.weight.toString()} KG<br/>
                            <img src={checkMark} className="Check-mark" alt="logo"/>HEIGHT: {this.state.height.toString()} CM<br/>
                        </p>
                        <div className="Main-container">
                            <p className="Description">
                                Where do you want to ride your setup?
                            </p>
                        </div>
                        <div className="Main-container">
                            <this.terrainButton terrainType={'trail'}/>
                            <this.terrainButton terrainType={'bikepark'}/>
                        </div>
                    </header>
                    <div className="Button-container">
                        <button onClick={this.handleClickBack.bind(this)} className="Back-button">GO BACK</button>
                        <button onClick={this.handleClick.bind(this)} className="Start-button">NEXT</button>
                    </div>
                </div>
            )
        } else if (step === 4) {
            return (
                <div className="App">
                    <header className="App-header">
                        <this.spinner/>
                        <div className="header-container">
                            <img src={logoAIIcon} className="App-logo-very-small" alt="logo"/>
                        </div>
                        <p className="List-selected">
                            <img src={checkMark} className="Check-mark" alt="logo"/>WEIGHT: {this.state.weight.toString()} KG<br/>
                            <img src={checkMark} className="Check-mark" alt="logo"/>HEIGHT: {this.state.height.toString()} CM<br/>
                            <img src={checkMark} className="Check-mark" alt="logo"/>TERRAIN: {this.state.terrain.toString().toUpperCase()}<br/>
                        </p>
                        <div className="Main-container">
                            <p className="Description">
                                WHAT FORK DO YOU RIDE?
                            </p>
                            <Select inputProps={{readOnly:true}} isSearchable={false} searchable={false} styles={customStyles} defaultValue={forkModels[0]} options={forkModels}
                                    onChange={this.selectValueForkModel}/>
                        </div>
                    </header>
                    <div className="Button-container">
                        <button onClick={this.handleClickBack.bind(this)} className="Back-button">GO BACK</button>
                        <button onClick={this.handleClickSubmit.bind(this)} className="Start-button">NEXT</button>
                    </div>
                </div>
            )
        } else if (step === 5) {
            console.log('###this.state.forkModel', this.state.forkModel);
                return (
                    <div className="App">
                        <header className="App-header" style={  {
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            backgroundImage: `url(${bgImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'}}>
                            <div className="header-container">
                                <img src={logoAIIcon} className="App-logo-very-small" alt="logo"/>
                            </div>
                            <p className="List-selected-last">
                                <div><img src={checkMark} className="Check-mark-last" alt="logo"/>WEIGHT: {this.state.weight.toString()} KG</div>
                                <div><img src={checkMark} className="Check-mark-last" alt="logo"/>HEIGHT: {this.state.height.toString()} CM</div>
                                <div><img src={checkMark} className="Check-mark-last" alt="logo"/>TERRAIN: {this.state.terrain.toString().toUpperCase()}</div>
                                <div><img src={checkMark} className="Check-mark-last" alt="logo"/>FORK MODEL: {this.state.forkModel.toString().toUpperCase()}</div>
                            </p>
                            <p className="Pitch">
                                HERE IS YOUR AI GENERATED FORK SETUP!
                            </p>
                            <this.mainContainer/>
                            <p className="hint">
                                HINT: Clicks always from fully closed clockwise! Also new Rockshox models!
                            </p>
                            <button onClick={this.showExplanationModal.bind(this)} className="help-button">
                                HOW DOES FORK AI SETUP ALGORITHM WORK? ℹ️
                            </button>
                            <this.explainModal/>
                        </header>
                        <div className="Button-container-Restart">
                            <button onClick={this.handleClickRestart.bind(this)} className="Back-button">RESTART</button>
                            <button className="Start-button" onClick={this.handleClick}>EXPORT SETTINGS</button>
                        </div>
                    </div>
                )
        } else if (step === 6) {
            return (
                <div className="App">
                    <header className="App-header" id="divToPrint" style={  {
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundImage: `url(${bgImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                        <div className="header-container">
                            <img src={logoAIIcon} className="App-logo-very-small" alt="logo"/>
                        </div>
                        <p className="List-selected-last">
                            <div><img src={checkMark} className="Check-mark-last" alt="logo"/>WEIGHT: {this.state.weight.toString()} KG</div>
                            <div><img src={checkMark} className="Check-mark-last" alt="logo"/>HEIGHT: {this.state.height.toString()} CM</div>
                            <div><img src={checkMark} className="Check-mark-last" alt="logo"/>TERRAIN: {this.state.terrain.toString().toUpperCase()}</div>
                            <div><img src={checkMark} className="Check-mark-last" alt="logo"/>FORK MODEL: {this.state.forkModel.toString().toUpperCase()}</div>
                        </p>
                        <p className="Pitch">
                            HERE IS YOUR AI GENERATED FORK SETUP!
                        </p>
                        <this.mainContainer/>
                        <p className="hint">
                            HINT: Clicks always from fully closed clockwise! Also new Rockshox models!
                        </p>
                    </header>
                    <header className="App-header-Form">
                        <p className="Pitch">
                            PLEASE LEAVE US SOME FEEDBACK BEFORE YOU PRINT!
                        </p>
                        <div className="Main-container-Form">
                            <form ref={this.ref} className="FormFeedback" onSubmit={this.printDocument.bind(this)}>
                                <div className="form-row">
                                    <label className="Description-form"> What is your name?</label>
                                    <input className="Input-form" type="text" name="name" />
                                </div>
                                <div className="form-row">
                                    <label className="Description-form"> What is your e-mail address?</label>
                                    <input  className="Input-form" type="email" name="mail" />
                                </div>
                                <div className="form-row">
                                    <label className="Description-form"> How useful do think SAGLY AI FORK SETUP is?</label>
                                    <select className="Input-form" name="usefulness">
                                        <option value="1">Not Useful</option>
                                        <option value="2">A Bit Useful</option>
                                        <option value="3">Useful</option>
                                        <option value="4">Very Useful</option>
                                    </select>
                                </div>
                                <div className="form-row">
                                    <label  className="Description-form">Please provide us some feedback. Is SAGLY AI FORK SETUP useful and do you
                                        like the suggested settings?</label>
                                    <textarea  className="Input-form" name="feedback" />
                                </div>
                                <div className="Button-container">
                                    <button onClick={this.handleClickRestart.bind(this)} className="Back-button">RESTART</button>
                                    <button className="Start-button" type="submit">SEND & EXPORT</button>
                                </div>
                            </form>
                        </div>
                    </header>
                </div>
            )
        }
    }
}

export default App;
