module.exports = {
    units: {
        "air_pressure_value": "PSI",
        "rebound_value": "CLICKS",
        "high_speed_rebound_value": "CLICKS",
        "low_speed_rebound_value": "CLICKS",
        "low_speed_compression_value": "CLICKS",
        "high_speed_compression_value": "CLICKS",
        "volume_spacer_value": "#",
        "air_pressure_positive_2_value": "PSI",
        "air_pressure_negative_1_value": "PSI",
        "air_pressure_positive_1_value": "PSI",
    },
    modelSettings:[
    {
        "Models": "36 Rythm GRIP / FIT 4",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "36 FLOAT E-Bike+ / E-Optimized GRIP / FIT 4",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "36 Factory / Performance Elite Grip2",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "FALSE",
        "high_speed_rebound_value": "TRUE",
        "low_speed_rebound_value": "TRUE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "36 FLOAT GRIP / FIT 4",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "38 Factory / Performance Elite Grip2",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "FALSE",
        "high_speed_rebound_value": "TRUE",
        "low_speed_rebound_value": "TRUE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "38 Performance GRIP / FIT 4",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "38 E-Bike Factory Grip2",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "FALSE",
        "high_speed_rebound_value": "TRUE",
        "low_speed_rebound_value": "TRUE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "34 Rythm GRIP / FIT 4",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "34 FLOAT GRIP / FIT 4",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "34 FLOAT Factory / Performance Elite SC/FIT4",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "FALSE",
        "high_speed_rebound_value": "TRUE",
        "low_speed_rebound_value": "TRUE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "40 FLOAT Performance GRIP",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "40 FLOAT Factory / Performance Elite GRIP 2",
        "Brand_value": "Fox Racing Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "FALSE",
        "high_speed_rebound_value": "TRUE",
        "low_speed_rebound_value": "TRUE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Gold 35",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Domain",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Lyrik Ultimate",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Lyrik Select, Select+",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Lyrik RCT3, RC2, RC",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Lyrik",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Boxxer Select, Select+",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Boxxer Ultimate",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Boxxer WC",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Boxxer",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Pike Ultimate",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Pike Select, Select+",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Pike RC",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Pike",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Recon",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Revelation",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Rock Shox 30",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Rock Shox 32",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Rock Shox 35",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "SID Ultimate",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "SID Select, Select+",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Yari",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "ZEB Ultimate",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "ZEB Select, Select+",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "TRUE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Judy",
        "Brand": "Rock Shox",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Bomber Z1",
        "Brand": "Marzocchi",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Bomber Z2",
        "Brand": "Marzocchi",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Bomber ZI",
        "Brand": "Marzocchi",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Helm",
        "Brand": "Cane Creek",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Durolux",
        "Brand": "SR Suntour",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "TRUE",
        "low_speed_rebound_value": "TRUE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Dorado",
        "Brand": "Manitou",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "DH 38",
        "Brand": "Öhlins",
        "air_pressure_value": "FALSE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "TRUE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Diamond",
        "Brand": "DVO",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Mezzer",
        "Brand": "Manitou",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "TRUE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Onyx",
        "Brand": "DVO",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "TRUE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "RXF",
        "Brand": "Ohlins",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
    {
        "Models": "Selva",
        "Brand_value": "Formula",
        "air_pressure_value": "TRUE",
        "rebound_value": "TRUE",
        "high_speed_rebound_value": "FALSE",
        "low_speed_rebound_value": "FALSE",
        "low_speed_compression_value": "TRUE",
        "high_speed_compression_value": "FALSE",
        "volume_spacer_value": "FALSE",
        "air_pressure_positive_2_value": "FALSE",
        "air_pressure_negative_1_value": "FALSE",
        "air_pressure_positive_1_value": "FALSE",
    },
        {
            "Models": "Era",
            "Brand": "EXT",
            "air_pressure_value": "TRUE",
            "rebound_value": "TRUE",
            "high_speed_rebound_value": "FALSE",
            "low_speed_rebound_value": "FALSE",
            "low_speed_compression_value": "TRUE",
            "high_speed_compression_value": "TRUE",
            "volume_spacer_value": "FALSE",
            "air_pressure_positive_2_value": "TRUE",
            "air_pressure_negative_1_value": "TRUE",
            "air_pressure_positive_1_value": "TRUE",
        }]
}