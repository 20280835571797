const headerColorStyle = "#FFF";
const headerTextColorStyle = "#FFF";
const buttonStyle = "#555";
const brightBlackStyle = "#1E1E1E" //bright-black
const blackStyle = "#000000"; //black
const notifBlackStyle = "#141414"; //notifBlackStyle
const notifGreyStyle = "#1e1e1e"; //notifGreyStyle
const brightGreyStyle = "#bfbfbf"; //bright-grey
const brightPlaceholderGreyStyle = "#898989"; //bright-grey-placeholder
const midDarkGreyStyle = "#4d4d4d"; //mid-dark-grey
const darkerGreyStyle = "#2d2d2d"; //darker-grey
const redStyle = "#f36666"; //red
const lightGreenStyle = "#58ff7d" //light-green
const orangeStyle = "#de985f" // orange
const placeholderComments = "#A7A7A7" // light-light-grey

export { placeholderComments, notifGreyStyle, notifBlackStyle, brightPlaceholderGreyStyle, brightBlackStyle, midDarkGreyStyle, orangeStyle, lightGreenStyle, headerColorStyle, headerTextColorStyle, buttonStyle, blackStyle, brightGreyStyle, darkerGreyStyle, redStyle };
