import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DocumentMeta from 'react-document-meta';

const meta = {
    title: 'SAGLY FORK AI SETUP',
    description: 'This AI Software from SAGLY helps you find custom settings for your mountain bike fork in seconds',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'mtb,mountainbike,setup,settings,setting,bike,calculator,ai,fork'
        }
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <DocumentMeta {...meta}/>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
